<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户" prop="userId">
                <a-input v-model="queryParam.userName" placeholder="请输入用户" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="举报内容" prop="resourceContent">
                <a-input v-model="queryParam.resourceContent" placeholder="请输入举报内容" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="处理状态">
                <a-select v-model="queryParam.processStatus" placeholder="请选择">
                  <a-select-option :value="1">待处理</a-select-option>
                  <a-select-option :value="2">已处理</a-select-option>
                  <a-select-option :value="3">暂不处理</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <a-button type="danger" :disabled="multiple" @click="handleDelete"  >
          <a-icon type="delete" />删除
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <approve-model
        ref="createForm"
        @ok="getList"
      />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="imgUrls" slot-scope="text, record">
          <template v-if="text">
              <span v-for="(item,index) in text.split(',')">
                  <img style="width: 48px;height: 48px;margin-right: 5px;margin-bottom: 5px;" v-image-preview :src="item" preview="图片"/>
              </span>
            </template>
        </span>

        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"   />
          <a @click="$refs.createForm.audit(record)" >
            <a-icon type="edit" />处理
          </a>
          <a-divider type="vertical"  />
          <a @click="handleDelete(record)"  >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageReport,listReport, delReport } from '@/api/user/report'
import ApproveModel from './modules/ApproveModel.vue'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Report',
  components: {
    ApproveModel
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userName: null,
        resourceContent: null,
        processStatus: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '主键ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报内容',
          dataIndex: 'resourceContent',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '举报原因',
          dataIndex: 'reason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报描述',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '相关证据',
          dataIndex: 'imgUrls',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'imgUrls' }
        },
        {
          title: '处理状态',
          dataIndex: 'processStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 1){
              return "待处理"
            }else if(value == 2){
              return "已处理"
            }else if(value == 3){
              return "暂不处理"
            }
            return "未知"
          }
        },
        {
          title: '处理结果',
          dataIndex: 'processResult',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询举报列表 */
    getList () {
      this.loading = true
     pageReport(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        resourceId: undefined,
        resourceContent: undefined,
        reason: undefined,
        content: undefined,
        imgUrls: undefined,
        processStatus: undefined,
        processResult: undefined,
        handleTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delReport(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-report/export', {
            ...that.queryParam
          }, `举报_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
